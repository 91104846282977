import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import { Dynamic } from "../context/DynamicContext";
import Commentaire from "./Commentaire";
import { SocketCont } from "../context/SocketContext";

const ListCommentaires = () => {
  const { commentaireImgMongo, setCommentaireImgMongo } = Dynamic();
  const { socket } = SocketCont();

  useEffect(() => {
    // setCommentaires();
    // console.log(commentaireImgMongo);
    if (socket) {
      const handleComment = (data) => {
        if (data) {
          setCommentaireImgMongo(data.commentaires);
        }
      };

      //on ecoute$
      socket.on("send_new_commentaire", handleComment);

      //on se désonne de l'évènement
      return () => socket.off("send_new_commentaire", handleComment);
    }
  }, [socket]);

  return (
    <StyledListCommentaires>
      {commentaireImgMongo ? (
        commentaireImgMongo
          .sort((a, b) => b.date.localeCompare(a.date))
          .map((item, index) => <Commentaire key={index} item={item} />)
      ) : (
        <strong>Pas de commentaire</strong>
      )}
    </StyledListCommentaires>
  );
};

export default ListCommentaires;

const StyledListCommentaires = styled.ul`
  position: absolute;
  top: 50px;
  height: 65%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  /* //iphoneX 375px */
  @media screen and (max-width: 376px) {
    top: 60px;
    height: 80% !important;
    strong {
      display: block;
      margin-top: 10px;
    }
  }

  //iphone xr
  @media screen and (max-width: 415px) {
    top: 65px !important;
    height: 80% !important;
    strong {
      display: block;
      margin-top: 10px;
    }
  }

  //iphone 12 prox max
  @media screen and (max-width: 428px) {
    top: 65px;
    height: 80%;
    strong {
      display: block;
      margin-top: 15px;
    }
  }
`;
