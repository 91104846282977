import React from "react";
import { FaShare } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";

const Share = ({ actionClick }) => {
  return (
    <StyledShare onClick={actionClick}>
      <FaShare className="icon-share" />
      <span>Partage</span>
    </StyledShare>
  );
};

export default Share;

const StyledShare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .icon-share {
    font-size: 1.7em;
    color: pink;
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    .icon-share {
      font-size: 2.4em;
    }
  }
`;
