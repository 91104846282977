import React, { useEffect } from "react";
import { FaFaceLaughSquint } from "react-icons/fa6";
import { FaMasksTheater } from "react-icons/fa6";
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import styled from "styled-components";

const Reactions = ({
  css,
  actionHoverOn,
  actionHoverOut,
  sendReactionUser,
  countReactions,
}) => {
  // useEffect(() => {
  //   console.log(countReactions);
  // }, []);
  return (
    <StyledReactions
      className={css}
      onMouseOver={actionHoverOn}
      onMouseLeave={actionHoverOut}
      //onTouchStart={actionOnTouchStart}
      //onTouchEnd={actionOnTouchEnd}
    >
      <div>
        <FcLikePlaceholder
          className="i-reaction-others "
          onClick={() => sendReactionUser("unlike")}
        />
      </div>
      <div>
        <FcLike
          className="i-reaction-others laugh"
          onClick={() => sendReactionUser("like")}
          //onTouchStart={() => sendReactionUser("like")}
          onTouchEnd={() => sendReactionUser("like")}
        />
        {countReactions && <span>{countReactions.like.length}</span>}
      </div>
      <div>
        <FaFaceLaughSquint
          className="i-reaction-others laugh"
          onClick={() => sendReactionUser("haha")}
          //onTouchStart={() => sendReactionUser("haha")}
          onTouchEnd={() => sendReactionUser("haha")}
        />
        <span>{countReactions.haha.length}</span>
      </div>
      <div>
        <FaMasksTheater
          className="i-reaction-others heater"
          onClick={() => sendReactionUser("grrr")}
          //onTouchStart={() => sendReactionUser("grrr")}
          onTouchEnd={() => sendReactionUser("grrr")}
        />
        <span>{countReactions.grrr.length}</span>
      </div>
    </StyledReactions>
  );
};

export default Reactions;
const StyledReactions = styled.div`
  cursor: default;
  .i-reaction-others {
    padding: 5px;
    font-size: 2.5em;
    color: yellow;
    transition: transform 0.3s ease;
    cursor: pointer !important;
  }
  .heater {
    font-size: 2.9em;
    color: #80f078;
  }
  .laugh:hover {
    animation: jumpAnimation 0.5s ease infinite;
  }
  .heater:hover {
    animation: jumpAnimationright 0.5s ease infinite;
  }
  @keyframes jumpAnimation {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-2px);
    }
  }
  @keyframes jumpAnimationright {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-5px);
    }
    60% {
      transform: translateX(-2px);
    }
  }
`;
