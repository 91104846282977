import React from "react";
import styled from "styled-components";

const ButtonForm = ({ text }) => {
  return <StyledButtonForm type="submit" value={text} />;
};

export default ButtonForm;

const StyledButtonForm = styled.input`
  background: greenyellow;
  border-radius: 10px;
  padding: 5px 25px;
  font-size: 1.1em;
  outline: none;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: greenyellow;
    background: grey;
    transition: 0.3s;
  }
`;
