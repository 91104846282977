import React from "react";
import styled from "styled-components";
import { LuRefreshCw } from "react-icons/lu";

const Buton = ({ actionClick }) => {
  return (
    <StyledButon onClick={actionClick}>
      One Piece
      <LuRefreshCw className="fresh" />
    </StyledButon>
  );
};

export default Buton;

const StyledButon = styled.button`
  background: blueviolet;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 1em;
  font-weight: 750;
  width: 50%;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  .fresh {
    margin-left: 15px;
    font-size: 1.3em;
    color: white;
  }

  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    width: 80%;
    font-size: 1.2em;
    .fresh {
      font-size: 1.5em;
    }
  }

  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    width: 80%;
  }
`;
