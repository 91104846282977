import React from "react";
import styled from "styled-components";
import { colors } from "../styles/styles";

const Footer = () => {
  return (
    <StyledFooter>
      <strong>©snap-boum v0.01|| dev.frenchlod@gmail.com</strong>
      <a
        href="https://www.facebook.com/profile.php?id=61554168009347"
        target="_blank"
      >
        {" "}
        || Suivez-nous sur Facebook || Signalé des bugs
      </a>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0px;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background: ${colors.footer};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  strong {
    font-size: 0.9em;
    /* color: ; */
  }
  a {
    color: white;
    font-size: 0.8em;
  }
`;
