import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";

const Loader = () => {
  return (
    <StyledLoader>
      {/* <span className="loader"></span> */}
      <img src="./items/logo.png" alt="logo snap boum" />
    </StyledLoader>
  );
};

export default Loader;

const StyledLoader = styled.div`
  position: fixed;
  z-index: 270;
  top: 0px;
  background: #201e1ed4;
  backdrop-filter: blur(5px);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20%;
    animation: scaleAnimation 2s infinite;
  }
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .loader {
    font-size: 48px;
    color: ${colors.primary};
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    background-color: currentcolor;
    position: relative;
    border-radius: 50%;
    transform: rotateX(-60deg) perspective(1000px);
  }
  .loader:before,
  .loader:after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    animation: flowerFlow 1s ease-out infinite;
  }
  .loader:after {
    animation-delay: 0.4s;
  }

  @keyframes flowerFlow {
    0% {
      opacity: 1;
      transform: rotate(0deg);
      box-shadow: 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor,
        0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor,
        0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor,
        0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor;
    }
    100% {
      opacity: 0;
      transform: rotate(180deg);
      box-shadow: -1em -1em 0 -0.35em currentcolor,
        0 -1.5em 0 -0.35em currentcolor, 1em -1em 0 -0.35em currentcolor,
        -1.5em 0 0 -0.35em currentcolor, 1.5em -0 0 -0.35em currentcolor,
        -1em 1em 0 -0.35em currentcolor, 0 1.5em 0 -0.35em currentcolor,
        1em 1em 0 -0.35em currentcolor;
    }
  }
`;
