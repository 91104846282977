import React from "react";
import { FaCommentAlt } from "react-icons/fa";
import styled from "styled-components";
import { Dynamic } from "../context/DynamicContext";
import FormCommentaire from "../interaction/FormCommentaire";
import ReactionActif from "../interaction/ReactionActif";

const CommentairesIcon = () => {
  const {
    user,
    setShowForm,
    setShowComments,
    showComments,
    commentaireImgMongo,
  } = Dynamic();
  const handleComment = (e) => {
    //si pas connecté
    if (!user) {
      setShowComments(false);
      return setShowForm(true);
    } else {
      if (showComments) {
        return e.stopPropagation();
      }
      setShowComments(!showComments);
    }

    //production 👇
    // if (showComments) {
    //   return e.stopPropagation();
    // }
    // setShowComments(!showComments);
    //toast.info("En cours de développement ..");
  };

  return (
    <StyledCommentaires onClick={(e) => handleComment(e)} $css={showComments}>
      {showComments ? (
        <>
          <ReactionActif />
          <FormCommentaire />
        </>
      ) : (
        <>
          {" "}
          <FaCommentAlt
            className="icon-add comment-only"
            onClick={() => setShowComments(!showComments)}
          />{" "}
          <span>
            {commentaireImgMongo ? commentaireImgMongo.length : 0}{" "}
            Commentaire(s)
          </span>
        </>
      )}
    </StyledCommentaires>
  );
};

export default CommentairesIcon;

const StyledCommentaires = styled.div`
  width: ${({ $css }) => ($css ? "80%" : "none")};
  height: ${({ $css }) => ($css ? "35vh" : "none")};
  background: ${({ $css }) => ($css ? "blueviolet" : "none")};
  border-radius: ${({ $css }) => ($css ? "10px" : "0px")};
  padding: ${({ $css }) => ($css ? "10px" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: ${({ $css }) => ($css ? "flex-start" : "center")};
  align-items: ${({ $css }) => ($css ? "flex-start" : "center")};
  cursor: pointer;
  .comment-only {
    color: white !important;
  }

  /* //iphoneX 375px */
  @media screen and (max-width: 376px) {
    width: ${({ $css }) => ($css ? "100%" : "none")};
  }

  //iphoneXR
  @media screen and (max-width: 416px) {
    width: ${({ $css }) => ($css ? "100%" : "none")};
  }

  //iphone 12 prox max
  @media screen and (max-width: 428px) {
    width: ${({ $css }) => ($css ? "100%" : "none")};
  }
  @media screen and (max-width: 600px) {
    height: ${({ $css }) => ($css ? "40vh" : "none")};
  }
`;
