import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../context/DynamicContext";
import Reactions from "./Reactions";
import ReactionActif from "./ReactionActif";
import { colors } from "../../styles/styles";
import axios from "axios";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import Commentaires from "../global/CommentairesIcon";
import { SocketCont } from "../context/SocketContext";
import Share from "../global/Share";
import AllIconsShare from "../global/AllIconsShare";
// const socket = io.connect("http://localhost:5000");

const Global = ({
  setReactionImageCurrent,
  reactionImageCurrent,
  idImgMongo,
  countReactions,
  setCountReactions,
}) => {
  const [idCatchImgCurrentBySocket, setIdCatchImgCurrentBySocket] =
    useState("");
  const [reaction, setReaction] = useState("unlike");
  const [tapeShare, setTapeShare] = useState(false);
  const { user, setShowForm, token, hoverReaction, setHoverReaction } =
    Dynamic();
  const { socket } = SocketCont();

  const sendReactionUser = async (react) => {
    //console.log(react);
    if (!user) {
      setShowForm(true);
      return;
    }
    // return;
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_LOCAL}aws/react/image`,
        withCredentials: true,
        data: {
          idimgmongo: idImgMongo,
          emailuser: user.email,
          react: react,
          token: token,
        },
      });
      setHoverReaction(false);
      //console.log(res);
      if (res.data.message === "ok") {
        socket.emit("catch_new_reaction", { id: idImgMongo });
      }
      if (res.data.message.includes("token incorrect")) {
        signOut(auth);
        return toast.error("Session expirée, connectez-vous pour continuer");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfUserHaveReaction = () => {
    if (reactionImageCurrent) {
      if (reactionImageCurrent.length) {
        for (let i = 0; i < reactionImageCurrent.length; i++) {
          if (reactionImageCurrent[i].emailuser === user.email) {
            return setReaction(reactionImageCurrent[i].reaction);
          } else if (
            reactionImageCurrent[i].emailuser === user.email &&
            idImgMongo === idCatchImgCurrentBySocket
          ) {
            return setReaction(reactionImageCurrent[i].reaction);
          } else {
            setReaction("unlike");
          }
        }
      } else {
        //quand reactionImageCurrent n'a pas de length cela signifie qu'il
        //qu'il y pas de reaction dedans
        setReaction("unlike");
      }
    }
  };

  useEffect(() => {
    if (socket) {
      const handleReaction = (data) => {
        // console.log("Réaction reçue du serveur :", data);
        if (data) {
          //to catch reaction in real time
          setIdCatchImgCurrentBySocket(data._id ? data._id : data._doc._id);
          if (data.reactions) {
            setCountReactions(data.reactions);
          }
          setReactionImageCurrent(
            data.reactionsusers ? data.reactionsusers : data._doc.reactionsusers
          );
          // setReactionImageCurrent(data.reactionsusers);
        }
      };

      socket.on("send_new_reaction", handleReaction);

      // Nettoyer l'écouteur d'événements lors du démontage du composant
      return () => {
        // console.log("Je me désabonne de l'événement 'send_new_reaction'");
        socket.off("send_new_reaction", handleReaction);
      };
    }
  }, [socket]);

  //n socket
  useEffect(() => {
    //console.log(idImgMongo);
    if (!user) {
      setReaction("unlike");
    } else {
      checkIfUserHaveReaction();
    }
    if (tapeShare) {
      setTapeShare(false);
    }
  }, [user, idImgMongo, reactionImageCurrent]);

  return (
    <StyledGlobal>
      {hoverReaction && (
        <Reactions
          css="float-reaction"
          setReaction={setReaction}
          sendReactionUser={sendReactionUser}
          countReactions={countReactions}
        />
      )}
      <div
        onClick={() => setHoverReaction(!hoverReaction)}
        // onClick={(e) => toHoverReactions(e)}
        className="reaction"
      >
        {<ReactionActif reaction={reaction} />}
        <span className="text-react">
          {`${reactionImageCurrent.length} Réaction(s)`}
        </span>
      </div>
      <Commentaires />
      <Share actionClick={() => setTapeShare(!tapeShare)} />
      {/* {tapeShare && <AllIconsShare tapeShare={tapeShare} />} */}
      {<AllIconsShare tapeShare={tapeShare} idImgMongo={idImgMongo} />}
    </StyledGlobal>
  );
};

export default Global;

const StyledGlobal = styled.div`
  background: blueviolet;
  position: relative;
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  div {
    /* background: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: grey; */
    cursor: pointer;
  }
  .icon-add {
    font-size: 1.7em;
    cursor: pointer;
    color: blueviolet;
  }
  .text-react {
    font-size: 0.8em;
  }
  .float-reaction {
    visibility: visible;
    transition: 0.3s;
    position: absolute;
    top: -65px;
    left: 0px;
    background: ${colors.transprantblack};
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px;
    border-radius: 5px;
  }
  .reaction {
    /* background: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  .reaction:hover {
    transition: 0.3s;
    transform: scale(1.2);
  }
  .no-haha {
    color: #80f078;
  }
  .haha {
    color: yellow;
  }
  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    width: 100%;
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    /* background: #80f078; */
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
    .reaction {
      font-size: 1.2em;
    }
    .icon-add {
      font-size: 1.9em;
    }
  }
`;
