import React, { useState } from "react";
import ButtonForm from "../global/ButtonForm";
import ButtonGlobal from "../global/ButtonGlobal";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import { toast } from "react-toastify";
import axios from "axios";
import {
  EmailAuthProvider,
  deleteUser,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth } from "../../firebase/firebase";

const DeleteCurrentUser = ({ setFormDelete, formDelete, user }) => {
  const [password, setPassword] = useState("");
  const deleteAccount = async (e) => {
    e.preventDefault();
    if (!password)
      return toast.error(
        "Votre mot de passe est nécessaire pour la suppression de votre compte"
      );
    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_LOCAL}user/current/delete`,
        withCredentials: true,
        data: {
          email: user.email,
          password: password,
        },
      });
      //   console.log(res);
      if (res.data.message === "ok") {
        try {
          const credential = EmailAuthProvider.credential(user.email, password);
          await reauthenticateWithCredential(auth.currentUser, credential);
          await deleteUser(auth.currentUser);
          return toast.success("Suppréssion de votre compte avec succès");
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      return toast.warn("Oups, une erreur inattendue est survenue");
    }
  };
  return (
    <StyledDeleteCurrentUser>
      {formDelete ? (
        <>
          <form onSubmit={(e) => deleteAccount(e)}>
            <span>Confirmez la suppréssion de votre compte : </span>
            <input
              type="password"
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonForm text={"Supprimer définitivement"} />
          </form>
          <ButtonGlobal
            actionClick={() => setFormDelete(!formDelete)}
            text={"Annuler"}
          />
        </>
      ) : (
        <strong
          className="delete-account"
          onClick={() => setFormDelete(!formDelete)}
        >
          Supprimer mon compte
        </strong>
      )}
    </StyledDeleteCurrentUser>
  );
};

export default DeleteCurrentUser;

const StyledDeleteCurrentUser = styled.div`
  /* background: white; */
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  strong {
    cursor: pointer;
    margin-top: 20px;
    color: ${colors.secondary};
  }
  form {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  input {
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 1em;
    margin-top: 10px;
  }
  input:last-child {
    background: red !important;
    color: white;
  }
`;
