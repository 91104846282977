import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";

const LoaderImage = () => {
  return (
    <StyledLoaderImage>
      <span className="loaderImg "></span>
    </StyledLoaderImage>
  );
};

export default LoaderImage;

const StyledLoaderImage = styled.div`
  transition: 0.3s;
  width: 60%;
  height: 95%;
  background: ${colors.lowtransparent};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  .loaderImg {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
    z-index: 11;
  }
  .loaderImg:before {
    content: "";
    width: 48px;
    height: 5px;
    background: pink;
    opacity: 0.25;
    position: absolute;
    z-index: 11;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
  }
  .loaderImg:after {
    content: "";
    width: 100%;
    height: 100%;
    background: pink;
    animation: bxSpin 0.5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
  }
  @keyframes bxSpin {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
    50% {
      transform: translateY(18px) scale(1, 0.9) rotate(45deg);
      border-bottom-right-radius: 40px;
    }
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  @keyframes shadow {
    0%,
    100% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1);
    }
  }

  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) and (max-width: 428) {
    width: 100%;
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    width: 100%;
  }
`;
