import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import Image from "./Image";

const Home = () => {
  return (
    <StyledHome>
      <Image />
    </StyledHome>
  );
};

export default Home;

const StyledHome = styled.div`
  background: ${colors.lowtransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 15px 13px 0px #44444496;

  //speciaux
  @media screen and (max-width: 884px) {
    width: 90%;
  }
  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 600px) {
    height: 95%;
  }
`;
