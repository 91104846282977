import React, { useState } from "react";
import {
  MdOutlineCancel,
  MdOutlineDeleteOutline,
  MdUpdate,
} from "react-icons/md";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Methode } from "../context/MethodeContext";
import { Dynamic } from "../context/DynamicContext";
import { colors } from "../../styles/styles";

const ChangeBiographie = ({ setFormChangeBio }) => {
  const [bio, setBio] = useState("");
  const { userMongo } = Dynamic();
  const { handleUpdateUser } = Methode();
  const handleBio = async (e) => {
    e.preventDefault();
    if (!bio) {
      handleDeleteBio(e);
    } else {
      if (bio.length > 110)
        return toast.error("Nombre de caractères autorisé dépassé");
      try {
        const res = await handleUpdateUser(bio);
        if (res === "Ok") {
          return setFormChangeBio(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteBio = async (e) => {
    e.preventDefault();
    if (window.confirm("Aucune bio ?")) {
      const deleteBio = "0";
      try {
        const res = await handleUpdateUser(deleteBio);
        if (res === "Ok") {
          return setFormChangeBio(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <StyledChangeBiographie onSubmit={(e) => handleBio(e)} $css={bio}>
      {bio.length > 90 && (
        <span>Nb encours : {bio.length} -Max 110 caractères</span>
      )}
      <textarea
        placeholder="Bio"
        onChange={(e) => setBio(e.target.value)}
        defaultValue={userMongo.biographie ? userMongo.biographie : undefined}
      ></textarea>
      {bio.length < 110 && (
        <strong onClick={handleBio}>
          <MdUpdate id="icon-send-new-pseudo" />
        </strong>
      )}
      <strong onClick={() => setFormChangeBio(false)}>
        <MdOutlineCancel id="cancel" />
      </strong>
      {userMongo.biographie && (
        <strong className="delete-bio" onClick={handleDeleteBio}>
          <MdOutlineDeleteOutline />
        </strong>
      )}
    </StyledChangeBiographie>
  );
};

export default ChangeBiographie;

const StyledChangeBiographie = styled.form`
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
  /* background: orange; */
  position: relative;
  span {
    position: absolute;
    bottom: -10px;
    font-size: 0.8em;
    color: ${colors.danger};
  }
  textarea {
    outline: none;
    resize: none;
    border: none;
    width: 100%;
    padding: 5px;
    margin: 0px 5px;
    border-radius: 5px;
    font-size: 1em;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
  strong {
    display: flex;
    align-items: center;
  }
  #icon-send-new-pseudo {
    font-size: 1.7em;
  }
  #cancel {
    color: red;
    margin-left: 10px;
  }
  .delete-bio {
    color: red;
    margin-left: 20px;
  }
`;
