import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import { MdInfoOutline, MdLink, MdOutlineClose } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";

const ProfilUserFromComment = ({ userFind, setUserFind }) => {
  return (
    <StyledProfilUserFromComment
      onDoubleClick={(e) => e.stopPropagation()}
      onClick={() => setUserFind([])}
    >
      <h2>Profil de {userFind.pseudo ? userFind.pseudo : "Non reconnu"}</h2>
      <strong className="close-up">
        <MdOutlineClose />
      </strong>
      <div onClick={(e) => e.stopPropagation()}>
        <div>
          <strong>
            <RxAvatar />
          </strong>
          <span>{userFind.pseudo ? userFind.pseudo : "Non reconnu"}</span>
        </div>
        <div>
          <strong>
            <MdInfoOutline />
          </strong>
          <span>
            {userFind.biographie ? userFind.biographie : "Aucune biographie"}
          </span>
        </div>
        <div>
          <strong>
            <MdLink />
          </strong>
          {userFind.link ? (
            <a href={userFind.link} target="_blank">
              {userFind.link}
            </a>
          ) : (
            <span>Aucun lien</span>
          )}
        </div>
        <div className="img-box-profil-user">
          <img src="./items/logo.png" alt="logo application" />
        </div>
      </div>
    </StyledProfilUserFromComment>
  );
};

export default ProfilUserFromComment;

const StyledProfilUserFromComment = styled.div`
  /* background: #9c9ca203; */
  background: #ececec;
  /* background: url("./items/background/background2.jpg"); */
  background-position: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 70vh;
  padding: 20px;
  border-radius: 10px;
  top: 0px;
  right: 50%;
  transform: translate(50%, -0%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    height: 5vh;
    margin-top: 50px;
    margin-bottom: 0px;
    /* background: orange; */
    color: #2ecc71;
    text-align: center;
  }
  div {
    width: 80%;
    padding: 10px;
  }
  div > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .img-box-profil-user {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .img-box-profil-user > img {
    width: 40%;
  }
  .close-up {
    position: absolute;
    top: 10px;
    right: 10px;
    color: orange;
  }
  strong {
    display: flex;
    font-size: 2em;
  }
  span {
    margin-left: 5px;
    color: blueviolet;
  }
  @media screen and (max-width: 600px) {
    top: 5%;
    div {
      width: 90%;
      padding: 00px;
    }
  }
`;
