import React, { useState } from "react";
import styled from "styled-components";
import ButtonGlobal from "../global/ButtonGlobal";
import { Dynamic } from "../context/DynamicContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { colors } from "../../styles/styles";
import DeleteCurrentUser from "../forms/DeleteCurrentUser";
import InfosUser from "./InfosUser";

const Dashboard = () => {
  const [formDelete, setFormDelete] = useState(false);
  const { user, token, showDashBoard, setShowDashBoard } = Dynamic();
  const handleLogout = async () => {
    if (user && token) {
      if (window.confirm("Vous vous déconnectez, Ok pour continuer")) {
        try {
          await signOut(auth);
          setShowDashBoard(!showDashBoard);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  return (
    <StyledDashboard
      $css={showDashBoard}
      onClick={() => setShowDashBoard(!showDashBoard)}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <h2>Mon Compte - {user.displayName}</h2>
        <InfosUser />
        <DeleteCurrentUser
          setFormDelete={setFormDelete}
          formDelete={formDelete}
          user={user}
        />
      </div>
      <ButtonGlobal actionClick={() => handleLogout()} text={"Déconnexion"} />
    </StyledDashboard>
  );
};

export default Dashboard;

const StyledDashboard = styled.div`
  position: fixed;
  background: ${colors.transprantblack};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: 3s;
  display: ${({ $css }) => ($css ? "block" : "none")};
  width: 100%;
  z-index: 15;
  height: 100vh;
  color: white;
  padding: 10px;
  div {
    padding: 10px;
    background: #4c4c4c;
    border-radius: 10px;
  }
  h2 {
    margin-bottom: 10px;
  }
  strong {
    cursor: pointer;
    color: ${colors.secondary};
  }
  .delete-account {
    cursor: pointer;
    margin: 50px 0px;
    color: ${colors.accent};
  }
  .delete-account:hover {
    color: red;
  }
  div {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
  }
`;
