import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../context/DynamicContext";
import ButtonForm from "../global/ButtonForm";
import { BsEmojiSmile } from "react-icons/bs";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Register = ({ setForm, email, setEmail }) => {
  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [hidden, setHidden] = useState("");
  const [showOne, setShowOne] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const { setSpin } = Dynamic();
  const handleRegister = async (e) => {
    e.preventDefault();
    setSpin(true);
    if (!pseudo || !email || !password || !confirmation)
      return toast.error("Les champs sont obligatoires");
    if (password !== confirmation)
      return toast.error("Les mots de passe ne correspondent pas");

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_LOCAL}user/auth/pub/register`,
        withCredentials: true,
        data: {
          pseudo,
          email,
          password,
          confirmation,
          hidden,
        },
      }).then((res) => {
        console.log(res);
        setSpin(false);
        if (res.data.message.includes("Erreur"))
          return toast.error(res.data.message);
        toast.success(res.data.message);
        setForm(true);
        return;
      });
    } catch (error) {
      console.log(error);
      setSpin(false);
      return toast.warn("Une erreur inattendue est survenue");
    }
  };
  return (
    <StyledRegister>
      <ToastContainer />
      <form onSubmit={handleRegister}>
        <div>
          <input
            type="text"
            placeholder="Pseudo*"
            className="input-register"
            onChange={(e) => setPseudo(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Email*"
            className="input-register"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type={showOne ? "text" : "password"}
            placeholder="Mot de passe*"
            className="input-register"
            onChange={(e) => setPassword(e.target.value)}
          />
          {showOne ? (
            <BsEmojiSmileUpsideDown
              className="icon-input-show"
              onClick={() => setShowOne(!showOne)}
            />
          ) : (
            <BsEmojiSmile
              className="icon-input-show"
              onClick={() => setShowOne(!showOne)}
            />
          )}
        </div>
        <div>
          <input
            type={showSecond ? "text" : "password"}
            placeholder="Confirmation mot de passe*"
            className="input-register"
            onChange={(e) => setConfirmation(e.target.value)}
          />
          {showSecond ? (
            <BsEmojiSmileUpsideDown
              className="icon-input-show"
              onClick={() => setShowSecond(!showSecond)}
            />
          ) : (
            <BsEmojiSmile
              className="icon-input-show"
              onClick={() => setShowSecond(!showSecond)}
            />
          )}
        </div>
        <input type="hidden" onChange={(e) => setHidden(e.target.value)} />
        <div>
          <ButtonForm text={"Je m'en registre"} />
        </div>
      </form>
    </StyledRegister>
  );
};

export default Register;

const StyledRegister = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  form > div {
    position: relative;
    /* background: blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  form > div > input {
    outline: none;
    border: none;
    margin-top: 5px;
  }
  .icon-input-show {
    transition: 0.3s;
    color: greenyellow;
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 1.5em;
  }
  .input-register {
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    font-size: 1.2em;
    border-bottom: solid 2px grey;
  }
  .input-register:focus {
    border-bottom: solid 2px greenyellow;
  }

  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    form {
      width: 80%;
    }
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    form {
      width: 80%;
    }
  }
`;
