import React from "react";
import { BiSolidDashboard } from "react-icons/bi";
import { Dynamic } from "../context/DynamicContext";
import "../../styles/StyleIconMenu.css";

const IconMenu = () => {
  const { showDashBoard, setShowDashBoard } = Dynamic();
  return (
    <BiSolidDashboard
      className={showDashBoard ? "actif" : "icon-dashboard"}
      onClick={() => setShowDashBoard(!showDashBoard)}
    />
  );
};

export default IconMenu;
