import { createContext, useContext } from "react";
import { toast } from "react-toastify";
import { Dynamic } from "./DynamicContext";
import axios from "axios";

const MethodeContext = createContext();

export const MethodeContextProvider = ({ children }) => {
  const { token, user, setSpin, callAgainToMongo, setCallAgainToMongo } =
    Dynamic();
  const handleUpdateUser = async (biographie, link) => {
    setSpin(true);
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_LOCAL}user/update/${user.uid}`,
        withCredentials: true,
        data: {
          biographie,
          link,
          token,
        },
      });
      if (res.data.message) {
        setSpin(false);
        return toast.error(res.data.message);
      } else {
        setSpin(false);
        setCallAgainToMongo(!callAgainToMongo);
        return "Ok";
      }
    } catch (error) {
      console.log(error);
      return toast.warn("Une erreur inattendue est survenue");
    }
  };
  return (
    <MethodeContext.Provider value={{ handleUpdateUser }}>
      {children}
    </MethodeContext.Provider>
  );
};

export const Methode = () => {
  return useContext(MethodeContext);
};
