import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import ButtonGlobal from "../global/ButtonGlobal";
import Connexion from "./Connexion";
import Register from "./Register";
import { Dynamic } from "../context/DynamicContext";

const Modal = () => {
  const [form, setForm] = useState(true);
  const [email, setEmail] = useState("");
  const { setShowForm, showForm, setHoverReaction } = Dynamic();
  useEffect(() => {
    if (showForm) {
      setHoverReaction(false);
    }
  }, [showForm]);

  return (
    <StyledModal
      onClick={() => setShowForm(false)}
      className="animate__animated animate__backInRight animate__faster"
    >
      <div className="first-div" onClick={(e) => e.stopPropagation()}>
        <ButtonGlobal
          actionClick={() => setForm(true)}
          text={"Connexion"}
          css={form ? "new-class" : undefined}
        />
        <ButtonGlobal
          actionClick={() => setForm(false)}
          text={"S'enregistrer"}
          css={!form ? "new-class" : undefined}
        />
        {form ? (
          <Connexion setEmail={setEmail} email={email} />
        ) : (
          <Register setForm={setForm} setEmail={setEmail} email={email} />
        )}
      </div>
    </StyledModal>
  );
};

export default Modal;

const StyledModal = styled.div`
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100vh;
  /* top: 50%;
  right: 50%;
  transform: translate(50%, -50%); */
  background: ${colors.transprantblack};
  backdrop-filter: blur(5px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .first-div {
    width: 50%;
    padding: 10px;
    background: white;
    border-radius: 10px;
  }
  .new-class {
    background: greenyellow;
    color: black;
  }
  .new-class:hover {
    background: grey;
    color: greenyellow;
  }
  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    .first-div {
      width: 100%;
    }
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    .first-div {
      width: 100%;
    }
  }
`;
