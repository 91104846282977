import styled from "styled-components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/global/Home";
import { Dynamic } from "./components/context/DynamicContext";
import Loader from "./components/global/Loader";
import Modal from "./components/forms/Modal";
import Dashboard from "./components/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconMenu from "./components/global/IconMenu";
import PopHomeInfo from "./components/interaction/PopHomeInfo";
import { Route, Routes } from "react-router-dom";

function App() {
  const { spin, showForm, user, token, messageHome } = Dynamic();
  return (
    <StyledApp className="app">
      {spin && <Loader />}
      {messageHome && <PopHomeInfo />}
      {showForm && <Modal />}
      {user && token && <Dashboard />}
      {user && token && <IconMenu />}
      <ToastContainer />
      <Header />
      <Routes>
        <Route path="/:id" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  position: relative;
  height: 110vh;
  background: url("./items/background/background6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding-top: 10px;
    height: 100vh;
    /* overflow-y: hidden; */
  }
`;
