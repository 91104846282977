import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import { IoIosInformationCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { Dynamic } from "../context/DynamicContext";
import axios from "axios";
import { SocketCont } from "../context/SocketContext";
import ProfilUserFromComment from "./ProfilUserFromComment";

const Commentaire = ({ item }) => {
  const [userFind, setUserFind] = useState([]);
  const { userMongo, idImgMongoToComment, token } = Dynamic();
  const { socket } = SocketCont();
  const instructionComment = () => {
    toast.error("Double-tape sur votre commentaire pour supprimer");
  };

  const deleteComment = async (id) => {
    if (
      window.confirm(
        "Voulez-vous vraiment supprimer votre commentaire ? Ok pour continuer"
      )
    ) {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_LOCAL}aws/comment/delete`,
        withCredentials: true,
        data: {
          token,
          idimgmongo: idImgMongoToComment,
          idcomment: id,
          iduser: userMongo._id,
          emailuser: userMongo.email,
        },
      });
      //   console.log(res);
      if (res.data.message === "ok") {
        socket.emit("catch_new_commentaire", { id: idImgMongoToComment });
      }
      if (res.data.message.includes("Erreur"))
        return toast.error(res.data.message);
    }
  };

  const displayProfil = async (id) => {
    // console.log(id);
    // console.log(token);
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_LOCAL}user/find/user/${id}`,
        withCredentials: true,
        params: {
          token,
        },
      });
      // console.log(res);
      if (res.data.message) {
        if (res.data.message.includes("Erreur"))
          return toast.error(res.data.message);
      }
      if (res.data) {
        setUserFind(res.data);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <StyledCommentaire
      $css={item}
      $cssid={userMongo._id}
      onDoubleClick={() => deleteComment(item._id)}
    >
      {userFind.pseudo && (
        <ProfilUserFromComment userFind={userFind} setUserFind={setUserFind} />
      )}
      <strong onClick={() => displayProfil(item.iduser)}>{item.pseudo}</strong>
      <li>{item.texte ? item.texte : item._id}</li>
      {item.iduser === userMongo._id && (
        <IoIosInformationCircle
          className="icon-info"
          onClick={instructionComment}
        />
      )}
    </StyledCommentaire>
  );
};

export default Commentaire;

const StyledCommentaire = styled.div`
  width: 100%;
  background: ${colors.lowtransparent};
  /* background: ${({ $css, $cssId }) =>
    $css.iduser === $cssId ? "pink" : "green"}; */
  margin-top: 5px;
  color: white;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  pointer-events: ${({ $css, $cssid }) =>
    $css.iduser === $cssid ? "all" : "none"};

  strong {
    margin-bottom: 5px;
    /* background: orange; */
    display: inline-block;
    color: ${colors.secondary};
    pointer-events: all;
    /* pointer-events: ${({ $css, $cssId }) =>
      $css.iduser === $cssId ? "none" : "all"}; */
  }
  li {
    margin-top: 5px;
  }
  .icon-info {
    position: absolute;
    color: white;
    top: 5px;
    right: 5px;
  }

  /* @media screen and (max-width: 376px) {
    margin-top: 10px;
  }

  //iphone 12 prox max
  @media screen and (max-width: 428px) {
    margin-top: 20px;
  } */
`;
