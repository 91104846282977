import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { DynamicContextProvider } from "./components/context/DynamicContext";
import { BrowserRouter } from "react-router-dom";
import { MethodeContextProvider } from "./components/context/MethodeContext";
import { SocketContextProvider } from "./components/context/SocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DynamicContextProvider>
        <SocketContextProvider>
          <MethodeContextProvider>
            <App />
          </MethodeContextProvider>
        </SocketContextProvider>
      </DynamicContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
