import React, { useState } from "react";
import { RxAvatar } from "react-icons/rx";
import { MdOutlineMailLock } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import { MdLink } from "react-icons/md";
import { Dynamic } from "../context/DynamicContext";
import styled from "styled-components";
import ChangePseudo from "../forms/ChangePseudo";
import ChangeBiographie from "../forms/ChangeBiographie";
import ChangeLink from "../forms/ChangeLink";
import { toast } from "react-toastify";
const InfosUser = () => {
  const [formChangePseudo, setFormChangePseudo] = useState(false);
  const [formChangeBio, setFormChangeBio] = useState(false);
  const [formChangeLink, setFormChangeLink] = useState(false);
  const { user, userMongo } = Dynamic();
  return (
    <StyledInfosUser>
      <li>
        <RxAvatar />{" "}
        {formChangePseudo ? (
          <ChangePseudo setFormChangePseudo={setFormChangePseudo} />
        ) : (
          <span onClick={() => setFormChangePseudo(true)}>
            {user.displayName}
          </span>
        )}
      </li>
      <li>
        <MdOutlineMailLock
          className="to-curser"
          onClick={() => toast.info("Vous êtes le seul a voir votre e-mail")}
        />{" "}
        <span
          onClick={() => toast.info("Vous êtes le seul a voir votre e-mail")}
        >
          {user.email}
        </span>
      </li>
      <li>
        <MdInfoOutline />{" "}
        {formChangeBio ? (
          <ChangeBiographie setFormChangeBio={setFormChangeBio} />
        ) : (
          <span onClick={() => setFormChangeBio(true)}>
            {userMongo.biographie ? userMongo.biographie : "Aucune bio"}
          </span>
        )}
      </li>
      <li>
        <MdLink />
        {formChangeLink ? (
          <ChangeLink setFormChangeLink={setFormChangeLink} />
        ) : (
          <span onClick={() => setFormChangeLink(true)}>
            {userMongo.link ? userMongo.link : "Aucun lien"}
          </span>
        )}
      </li>
    </StyledInfosUser>
  );
};

export default InfosUser;

const StyledInfosUser = styled.ul`
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* background: white; */
  }
  .to-curser {
    cursor: pointer;
  }
  li > span {
    color: yellowgreen !important;
    margin-left: 5px;
    cursor: pointer;
  }
`;
