import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { updateProfile } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { colors } from "../../styles/styles";
import { Dynamic } from "../context/DynamicContext";
import { MdOutlineCancel } from "react-icons/md";
import { MdUpdate } from "react-icons/md";

const ChangePseudo = ({ setFormChangePseudo }) => {
  const [newPseudo, setNewPseudo] = useState("");
  const { setCallAgain, user, callAgain, token } = Dynamic();
  const changePseudo = async (e) => {
    e.preventDefault();
    if (!newPseudo) return toast.error("Un nouveau pseudo est nécessaire");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_LOCAL}user/update/${user.uid}`,
        withCredentials: true,
        data: {
          pseudo: newPseudo,
          token,
        },
      });
      // console.log(res);
      if (res.data.message) return toast.error(res.data.message);
      updateProfile(auth.currentUser, {
        displayName: newPseudo,
      })
        .then(() => {
          setFormChangePseudo(false);
          setCallAgain(!callAgain);
          return toast.success("Le pseudo a été changé avec succès");
        })
        .catch((error) => {
          console.log(error);
          return toast.warn("Une erreur inattendue est survenue");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StyledChangePseudo onSubmit={(e) => changePseudo(e)}>
        <input
          type="text"
          placeholder="Entrez le nouveau pseudo"
          onChange={(e) => setNewPseudo(e.target.value)}
        />
        {newPseudo.length > 3 && (
          <strong onClick={changePseudo}>
            <MdUpdate id="icon-send-new-pseudo" />
          </strong>
        )}
        <strong onClick={() => setFormChangePseudo(false)}>
          <MdOutlineCancel id="cancel" />
        </strong>
      </StyledChangePseudo>
    </>
  );
};

export default ChangePseudo;

const StyledChangePseudo = styled.form`
  display: flex;
  width: 50%;
  padding: 5px;
  align-items: center;
  input {
    outline: none;
    border: none;
    padding: 5px;
    margin: 0px 5px;
    border-radius: 5px;
    font-size: 1em;
  }
  strong {
    display: flex;
    align-items: center;
  }
  #icon-send-new-pseudo {
    font-size: 1.7em;
  }
  #cancel {
    color: red;
    margin-left: 10px;
  }
  span {
    cursor: pointer;
    margin-top: 10px;
    color: ${colors.secondary};
  }

  //iphoneX 375px
  @media screen and (max-width: 376px) {
    width: 90%;
  }
  //iphoneX 416px
  @media screen and (max-width: 416px) {
    width: 90%;
  }

  //iphone 12 pro max 428px
  @media screen and (max-width: 428px) {
    width: 90%;
  }
`;
