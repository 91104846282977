import React from "react";
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { FaFaceLaughSquint } from "react-icons/fa6";
import { FaMasksTheater } from "react-icons/fa6";
import { Dynamic } from "../context/DynamicContext";
const ReactionActif = ({ reaction }) => {
  const { setShowComments, showComments } = Dynamic();
  const closeSpaceComment = () => {
    if (showComments) return setShowComments(!showComments);
  };
  const actif = () => {
    // console.log(reaction);
    switch (reaction) {
      case "unlike":
        return (
          <FcLikePlaceholder className="icon-add" onClick={closeSpaceComment} />
        );
      case "like":
        return <FcLike className="icon-add" onClick={closeSpaceComment} />;
      case "haha":
        return (
          <FaFaceLaughSquint
            className="icon-add haha"
            onClick={closeSpaceComment}
          />
        );
      case "grrr":
        return (
          <FaMasksTheater
            className="icon-add no-haha"
            onClick={closeSpaceComment}
          />
        );

      default:
        return (
          <FcLikePlaceholder className="icon-add" onClick={closeSpaceComment} />
        );
    }
  };
  return <>{actif()}</>;
};

export default ReactionActif;
