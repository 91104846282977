import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../firebase/firebase";
import axios from "axios";

const DynamicContext = createContext();

export const DynamicContextProvider = ({ children }) => {
  const [spin, setSpin] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [userMongo, setUserMongo] = useState([]);
  const [idImgMongoToComment, setIdImgMongoToComment] = useState("");
  const [showDashBoard, setShowDashBoard] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [callAgainToMongo, setCallAgainToMongo] = useState(false);
  const [hoverReaction, setHoverReaction] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentaireImgMongo, setCommentaireImgMongo] = useState([]);
  const [messageHome, setMessageHome] = useState(false);

  const getUserCurrentMongo = async (id) => {
    try {
      //get user current in Mongo
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_LOCAL}user/one/${id}`,
        withCredentials: true,
      });
      if (res.data) {
        // console.log(res.data);
        setUserMongo(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //call when image current change
  const getImageCurrentMongo = () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  //
  useEffect(() => {
    const callIfAuthChange = async () => {
      setSpin(true);
      onAuthStateChanged(auth, (usercurrent) => {
        if (usercurrent) {
          setSpin(false);
          setUser(usercurrent);
          usercurrent.getIdToken().then((res) => {
            setToken(res);
            if (usercurrent && res) {
              setShowForm(false);
              getUserCurrentMongo(usercurrent.uid);
            }
          });

          //pas de redirection
        } else {
          setSpin(false);
          setToken("");
          setUser(null);
          setUserMongo([]);
          //pas de userCurrent alors on désactive tout
        }
      });
    };
    callIfAuthChange();
  }, [callAgain]);

  useEffect(() => {
    if (user) {
      getUserCurrentMongo(user.uid);
    }
  }, [callAgainToMongo]);
  useEffect(() => {
    if (userMongo) {
      setMessageHome(true);
    }
  }, [userMongo]);
  return (
    <DynamicContext.Provider
      value={{
        spin,
        setSpin,
        user,
        setUser,
        showForm,
        setShowForm,
        token,
        setToken,
        getImageCurrentMongo,
        userMongo,
        setUserMongo,
        callAgain,
        setCallAgain,
        showDashBoard,
        setShowDashBoard,
        hoverReaction,
        setHoverReaction,
        showComments,
        setShowComments,
        callAgainToMongo,
        setCallAgainToMongo,
        idImgMongoToComment,
        setIdImgMongoToComment,
        commentaireImgMongo,
        setCommentaireImgMongo,
        messageHome,
        setMessageHome,
      }}
    >
      {children}
    </DynamicContext.Provider>
  );
};

export const Dynamic = () => {
  return useContext(DynamicContext);
};
