import React from "react";
import { FaFacebook } from "react-icons/fa6";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import { Dynamic } from "../context/DynamicContext";
import { MdOutlineClose } from "react-icons/md";

const PopHomeInfo = () => {
  const { setMessageHome } = Dynamic();
  return (
    <StyledPopHomeInfo onClick={() => setMessageHome(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        <strong onClick={() => setMessageHome(false)}>
          <MdOutlineClose />
        </strong>
        <h1>Bienvenue sur Snap Boum</h1>
        <p>Suivez l'évolution de notre application sur facebook</p>
        <div>
          <a
            href="https://www.facebook.com/profile.php?id=61554168009347"
            id="link-to-open-facebook"
            target="_blank"
          >
            {" "}
            <FaFacebook />
          </a>
        </div>
      </div>
    </StyledPopHomeInfo>
  );
};

export default PopHomeInfo;

const StyledPopHomeInfo = styled.div`
  background: ${colors.transprantblack};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100vh;
  top: 0px;
  width: 100%;
  position: fixed;
  z-index: 55;
  color: #2ecc71;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    height: 30%;
    /* background: white; */
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  div > strong {
    font-size: 2em;
    cursor: pointer;
    color: #2ecc71;
  }

  #link-to-open-facebook {
    display: block;
    font-size: 3em;
    color: #2ecc71;
    animation: rebondissement 1s ease infinite;
  }
  @keyframes rebondissement {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0); /* Aucun rebondissement */
    }
    40% {
      transform: translateY(-20px); /* Rebondissement vers le haut */
    }
    60% {
      transform: translateY(-10px); /* Rebondissement vers le bas */
    }
  }
`;
