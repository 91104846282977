import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  // socketListen();
  useEffect(() => {
    // Créer la connexion du socket lors du montage du composant
    const socketInstance = io.connect(`${process.env.REACT_APP_API_LOCAL}`);
    setSocket(socketInstance);

    // Nettoyer la connexion du socket lors du démontage du composant
    return () => {
      socketInstance.disconnect();
    };
  }, []);
  return (
    <SocketContext.Provider value={{ socket, setSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const SocketCont = () => {
  return useContext(SocketContext);
};
