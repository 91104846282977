import React from "react";
import styled from "styled-components";
import { colors } from "../styles/styles";

const Header = () => {
  return (
    <StyledHeader>
      <img src="./items/logo.png" alt="logo-snap-boum" />
      <hr />
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  margin-bottom: 20px;
  width: 100%;
  padding: 20px;
  /* border-bottom: solid 2px white; */
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  img {
    display: block;
    width: 5%;
  }
  hr {
    width: 50%;
  }

  //tablette 768px
  @media screen and (max-width: 768px) {
    img {
      width: 15%;
    }
  }

  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    img {
      width: 25%;
    }
  }

  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    img {
      width: 30%;
    }
  }

  @media screen and (max-width: 600px) {
    position: fixed;
    z-index: 50;
    background: blue;
    padding: 0px;
    height: 0px;
    margin: 0px;
    width: 10%;
    bottom: 70px;
    left: 0px;
    img {
      width: 100%;
    }
  }
`;
