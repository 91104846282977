import React from "react";
import styled from "styled-components";

const ButtonGlobal = ({ actionClick, text, css }) => {
  return (
    <StyledButtonGlobal onClick={actionClick} className={css}>
      {text}
    </StyledButtonGlobal>
  );
};

export default ButtonGlobal;

const StyledButtonGlobal = styled.button`
  transition: 0.3s;
  background: blueviolet;
  color: greenyellow;
  margin: 0px 5px;
  padding: 5px 15px;
  font-size: 1.1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    background: greenyellow;
    color: black;
  }
`;
