import React, { useState } from "react";
import styled from "styled-components";
import ButtonForm from "../global/ButtonForm";
import { BsEmojiSmile } from "react-icons/bs";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { Dynamic } from "../context/DynamicContext";
import axios from "axios";

const Connexion = ({ email, setEmail }) => {
  const [showPass, setShowPass] = useState(false);
  const [passwordForget, setPasswordForget] = useState(false);
  const [password, setPassword] = useState("");
  const { setUser, setSpin, setToken } = Dynamic();
  const handleLogin = async (e) => {
    e.preventDefault();
    setSpin(true);
    if (!password || !email) {
      setSpin(false);
      return toast.error("Les champs sont obligatoires");
    }
    try {
      await signInWithEmailAndPassword(auth, email, password).then(
        (userCredential) => {
          //si user connected la fonction dans Dynamic dans useEffect ce joue;
          const userActuel = userCredential.user;
          setSpin(false);
          setUser(userActuel);
          userActuel.getIdToken().then((res) => {
            setToken(res);
          });
        }
      );
    } catch (error) {
      setSpin(false);
      console.log(error);
      if (error.code.includes("invalid"))
        return toast.error("Mot de passe ou email invalid");
      return toast.warn("Une erreur inattendue est survenue");
    }
  };

  //reset password
  const resetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);
    if (!email) return toast.error("Email obligatoire");
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_LOCAL}user/auth/init/password`,
        withCredentials: true,
        data: {
          email,
        },
      });
      setSpin(false);
      if (res.data.message)
        toast.success(
          "Un mail pour initialiser votre mot de passe a été envoyé"
        );
    } catch (error) {
      console.log(error);
      setSpin(false);
      return toast.warn("Une erreur inattendu");
    }
  };
  return (
    <StyledConnexion>
      <form onSubmit={passwordForget ? resetPassword : handleLogin}>
        <ToastContainer />
        <div>
          <input
            type="email"
            placeholder="Email*"
            className="input-connexion"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {!passwordForget && (
          <div>
            <input
              type={showPass ? "text" : "password"}
              placeholder="Mot de passe*"
              className="input-connexion"
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPass ? (
              <BsEmojiSmileUpsideDown
                className="icon-input-show-connexion"
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <BsEmojiSmile
                className="icon-input-show-connexion"
                onClick={() => setShowPass(!showPass)}
              />
            )}
          </div>
        )}
        <div>
          <ButtonForm
            text={
              passwordForget ? "Initialiser mon mot de passe" : "Se connecter"
            }
          />
        </div>
        <span onClick={() => setPasswordForget(!passwordForget)}>
          Mot de passe oublié ?
        </span>
      </form>
    </StyledConnexion>
  );
};

export default Connexion;

const StyledConnexion = styled.div`
  /* background: blue; */
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    width: 50%;
    /* background: orange; */
  }
  form > div {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  form > span {
    cursor: pointer;
  }
  form > div > input {
    /* width: 50%; */
    outline: none;
    border: none;
    margin-top: 5px;
  }
  .input-connexion {
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    font-size: 1.2em;
    border-bottom: solid 2px grey;
  }
  .input-connexion:focus {
    /* color: greenyellow; */
    border-bottom: solid 2px greenyellow;
  }
  .icon-input-show-connexion {
    transition: 0.3s;
    color: greenyellow;
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 1.5em;
  }
  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    form {
      width: 80%;
    }
  }
  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    form {
      width: 80%;
    }
  }
`;
