import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ListCommentaires from "./ListCommentaires";
import { BsFillSendFill } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { Dynamic } from "../context/DynamicContext";
import { SocketCont } from "../context/SocketContext";
import { colors } from "../../styles/styles";

const FormCommentaire = () => {
  const [commentaire, setCommentaire] = useState("");
  const { token, user, idImgMongoToComment, setShowForm } = Dynamic();
  const { socket } = SocketCont();

  const sendComment = async () => {
    if (!token || !user) {
      setShowForm(true);
    }
    if (!commentaire) return toast.error("Aucun commentaire n'a été saisi");
    if (commentaire && commentaire.length > 150)
      return toast.error("Nombre de caractères autorisés dépassé");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_LOCAL}aws/comment/image`,
        withCredentials: true,
        data: {
          token,
          comment: commentaire,
          idimgmongo: idImgMongoToComment,
          emailuser: user.email,
        },
      });
      //   console.log(res);
      if (res.data.message === "ok") {
        setCommentaire("");
        socket.emit("catch_new_commentaire", { id: idImgMongoToComment });
      }
    } catch (error) {
      toast.error("Une erreur inattendue est survenue");
      console.log(error);
    }
  };
  return (
    <StyledFormCommentaire
      onClick={(e) => e.stopPropagation()}
      $css={commentaire}
    >
      <div className="box-form-comment">
        <textarea
          placeholder="Commentaire..."
          onChange={(e) => setCommentaire(e.target.value)}
          value={commentaire && commentaire}
        ></textarea>
        <BsFillSendFill className="icon-send" onClick={sendComment} />
        <span>
          {commentaire.length < 140
            ? `caractère${commentaire.length > 1 ? "s" : ""} : ${
                commentaire.length
              }`
            : `caractères ${commentaire.length} - limite max : 150`}
        </span>
      </div>
      <ListCommentaires />
    </StyledFormCommentaire>
  );
};

export default FormCommentaire;

const StyledFormCommentaire = styled.div`
  margin-top: 5px;
  position: relative;
  height: 100%;
  width: 100%;
  .box-form-comment {
    position: absolute;
    top: 0px;
    /* background: orange; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    align-items: center;
    border-radius: 5px;
    /* padding: 5px; */
  }
  .box-form-comment > span {
    width: 100%;
    font-size: 0.8em;
    color: ${({ $css }) => ($css.length > 140 ? colors.danger : "white")};
  }
  .icon-send {
    font-size: 1.4em;
    margin-left: 5px;
    color: ${({ $css }) => ($css.length > 150 ? colors.danger : "white")};
  }
  .box-form-comment > textarea {
    height: 7vh;
    padding: 5px;
    width: 90%;
    outline: none;
    border-radius: 3px;
    border: none;
    resize: none;
    font-size: 1.2em;
    font-weight: 500;
  }
  .box-form-comment > textarea::placeholder {
    font-style: italic;
  }
  .box-form-comment > textarea::-webkit-scrollbar {
    display: none;
  }
`;
