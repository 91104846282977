import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Buton from "./Buton";
import axios from "axios";
import { Dynamic } from "../context/DynamicContext";
import Global from "../interaction/Global";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderImage from "./LoaderImage";
import Commentaires from "./CommentairesIcon";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const Image = () => {
  const [imgLink, setImgLink] = useState("");
  const [reactionImageCurrent, setReactionImageCurrent] = useState([]);
  const [countReactions, setCountReactions] = useState([]);
  const [idImgMongo, setIdImgMongo] = useState("");
  const [loaderImg, setLoaderImg] = useState(false);
  const [idImg, setIdImg] = useState(null);
  const {
    setHoverReaction,
    showComments,
    setShowComments,
    setIdImgMongoToComment,
    setCommentaireImgMongo,
    commentaireImgMongo,
  } = Dynamic();
  const lien = useLocation();
  const goPage = useNavigate();
  const checkIfId = () => {
    if (lien.pathname.length > 1) {
      console.log(lien.pathname.length);
      // console.log("je suis ici");
      const id = lien.pathname.substring(1).replace(/"/g, "");
      // goPage("/");
      setIdImg(id);
      const withId = `${process.env.REACT_APP_API_LOCAL}aws/random/one/image/${id}`;
      callImageWithId(withId);
    } else {
      console.log("je suis icic dans else");
      callImageWithoutId();
    }
  };
  const callImageWithoutId = async () => {
    setHoverReaction(false);
    setLoaderImg(true);

    try {
      const urlOpt = `${process.env.REACT_APP_API_LOCAL}aws/random/one/image/`;

      // console.log(urlOpt);
      await axios({
        method: "get",
        url: urlOpt,
        withCredentials: true,
      }).then((res) => {
        console.log(res);
        setImgLink(res.data.nameimage);
        setIdImgMongo(res.data._id);
        setIdImgMongoToComment(res.data._id);
        setReactionImageCurrent(res.data.reactionsusers);
        setCountReactions(res.data.reactions);
        setShowComments(false);
        setCommentaireImgMongo(res.data.commentaires);
        lien.pathname = "";
        setTimeout(() => {
          setLoaderImg(false);
        }, 1000);
        return;
      });
    } catch (error) {
      setLoaderImg(false);
      console.log(error);
      if (error.code) return toast.warning("Site en cours de maintenance");
    }
  };

  const callImageWithId = async (url) => {
    setHoverReaction(false);
    setLoaderImg(true);

    try {
      // console.log(urlOpt);
      await axios({
        method: "get",
        url: url,
        withCredentials: true,
      }).then((res) => {
        console.log(res);
        setImgLink(res.data._doc.nameimage);
        setIdImgMongo(res.data._doc._id);
        setIdImgMongoToComment(res.data._doc._id);
        setReactionImageCurrent(res.data._doc.reactionsusers);
        setCountReactions(res.data.reactions);
        setShowComments(false);
        setCommentaireImgMongo(res.data._doc.commentaires);
        lien.pathname = "";
        setTimeout(() => {
          setLoaderImg(false);
        }, 1000);
        return;
      });
    } catch (error) {
      setLoaderImg(false);
      console.log(error);
      if (error.code) return toast.warning("Site en cours de maintenance");
    }
  };

  useEffect(() => {
    checkIfId();
  }, []);

  //no id
  const metaDescript = () => {
    if (!idImg) {
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Snap-Boum 😂🤧</title>
          <meta
            name="description"
            content="Snap-boum est une application web qui envoie des images trouvées sur les réseaux sociaux. Ces images peuvent être offensantes pour certaines personnalités. En naviguant sur Snap-Boum, vous acceptez de ne pas être fragile."
          />
          <meta property="og:image" content={"./items/logo.png"} />
        </Helmet>
      );
    } else {
      const numComment = commentaireImgMongo ? commentaireImgMongo.length : 0;
      const numReact = reactionImageCurrent ? reactionImageCurrent.length : 0;
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Snap-Boum 😂🤧</title>
          <meta
            name="description"
            content={`Reaction(s) : ${numReact} || Commentaire(s) : ${numComment}`}
          />
          <meta property="og:image" content={imgLink} />
        </Helmet>
      );
    }
  };

  return (
    <StyledImage>
      {metaDescript()}
      <Buton actionClick={callImageWithoutId} />
      <div className="div-img-unik">
        {loaderImg && <LoaderImage />}
        <img src={imgLink && imgLink} alt="L'image current" />
      </div>
      {showComments ? (
        <Commentaires />
      ) : (
        <Global
          reactionImageCurrent={reactionImageCurrent}
          setReactionImageCurrent={setReactionImageCurrent}
          idImgMongo={idImgMongo}
          countReactions={countReactions}
          setCountReactions={setCountReactions}
        />
      )}
    </StyledImage>
  );
};

export default Image;

const StyledImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 80%;
  .div-img-unik {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  .div-img-unik > img {
    user-select: none;
    display: block;
    width: 60%;
    border-radius: 10px;
    margin: 10px 0px;
  }

  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //iphoneX 375px And IphoneXr 415px
  @media screen and (min-width: 374px) and (max-width: 416px) {
    .div-img-unik > img {
      width: 100%;
    }
  }

  //iphone12 pro max 428px
  @media screen and (max-width: 429px) {
    .div-img-unik > img {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;
