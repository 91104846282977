import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/styles";
import {
  MdOutlineCancel,
  MdOutlineDeleteOutline,
  MdUpdate,
} from "react-icons/md";
import { toast } from "react-toastify";
import { Methode } from "../context/MethodeContext";
import { Dynamic } from "../context/DynamicContext";

const ChangeLink = ({ setFormChangeLink }) => {
  const [link, setLink] = useState("");
  const { userMongo } = Dynamic();
  const { handleUpdateUser } = Methode();
  const handleLink = async (e) => {
    e.preventDefault();
    if (!link) {
      handleDeleteLink(e);
    } else {
      try {
        const res = await handleUpdateUser(null, link);
        if (res === "Ok") {
          return setFormChangeLink(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteLink = async (e) => {
    e.preventDefault();
    if (window.confirm("Aucun lien ?")) {
      const deleteLink = "0";
      try {
        const res = await handleUpdateUser(null, deleteLink);
        if (res === "Ok") {
          return setFormChangeLink(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <StyledChangeLink onSubmit={(e) => handleLink(e)}>
      <input
        type="text"
        placeholder="Entrez un lien"
        onChange={(e) => setLink(e.target.value)}
        defaultValue={userMongo.link ? userMongo.link : undefined}
      />
      <strong onClick={handleLink}>
        <MdUpdate id="icon-send-new-pseudo" />
      </strong>
      <strong onClick={() => setFormChangeLink(false)}>
        <MdOutlineCancel id="cancel" />
      </strong>
      {userMongo.link && (
        <strong>
          <MdOutlineDeleteOutline
            className="delete-link"
            onClick={handleDeleteLink}
          />
        </strong>
      )}
    </StyledChangeLink>
  );
};

export default ChangeLink;

const StyledChangeLink = styled.form`
  display: flex;
  width: 50%;
  padding: 5px;
  align-items: center;
  input {
    outline: none;
    border: none;
    padding: 5px;
    margin: 0px 5px;
    border-radius: 5px;
    font-size: 1em;
  }
  strong {
    display: flex;
    align-items: center;
  }
  #icon-send-new-pseudo {
    font-size: 1.7em;
  }
  #cancel {
    color: red;
    margin-left: 10px;
  }
  span {
    cursor: pointer;
    margin-top: 10px;
    color: ${colors.secondary};
  }
  .delete-link {
    color: red;
    margin-left: 20px;
  }
  //iphoneX 375px
  @media screen and (max-width: 376px) {
    width: 90%;
  }
  //iphoneX 416px
  @media screen and (max-width: 416px) {
    width: 90%;
  }

  //iphone 12 pro max 428px
  @media screen and (max-width: 428px) {
    width: 90%;
  }
`;
