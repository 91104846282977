import React, { useState } from "react";
import { FacebookShare, TwitterShare, WhatsappShare } from "react-share-kit";
import styled from "styled-components";

const AllIconsShare = ({ tapeShare, idImgMongo }) => {
  const [isSharing, setIsSharing] = useState(false);
  const urlShare = `https://www.snap-boum.fr/${idImgMongo}`;
  const handlePopShare = async () => {
    setIsSharing(!isSharing);
    // if (isSharing) {
    //   return;
    // }
    // // Définir que le partage est en cours
    // setIsSharing(true);
    // try {
    //   await navigator.share({
    //     title: "Partager sur snap-boum.fr",
    //     text: "Découvrez ce contenu sur snap-boum.fr",
    //     url: `https://www.snap-boum.fr/${idImgMongo}`,
    //   });
    // } catch (error) {
    //   console.log(error);
    //   toast.error("Une erreur est survenue");
    // } finally {
    //   // Réinitialiser l'état après que le partage est terminé (réussi ou échoué)
    //   setIsSharing(false);
    // }
  };
  return (
    <StyledAllIconsShare
      className={tapeShare ? "upIcons" : ""}
      $css={tapeShare}
    >
      <span className="icon-social">
        <FacebookShare url={urlShare} />
      </span>
      <span className="icon-social">
        <TwitterShare url={urlShare} title={"😂 Viens rire"} />
      </span>
      <span className="icon-social">
        <WhatsappShare
          url={urlShare}
          title={"Viens voir ici 😂"}
          separator=":: "
        />
      </span>
    </StyledAllIconsShare>
  );
};

export default AllIconsShare;

const StyledAllIconsShare = styled.div`
  position: absolute;
  top: ${({ $css }) => ($css ? "-60px" : "0px")};
  z-index: ${({ $css }) => ($css ? "20" : "-10")};
  width: 100%;
  display: flex;
  flex-direction: row !important;
  transition: 0.5s ease-in-out;
  .icon-social {
    display: block;
    padding: 10px;
  }
  .icon-social > button > svg {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    box-shadow: 0 0 10px rgba(128, 0, 128, 0.7);
    -webkit-box-shadow: 0 0 10px rgba(128, 0, 128, 0.7);
  }
`;
